<template>
  <!-- 共用 vendor 類別版塊 -->
  <div
    :id="'vendor-' + data.block"
    class="vendor-type my-3"
  >
    <!-- HEADER -->
    <div
      class="d-flex flex-column justify-center align-center pt-1 card1"
    >
      <!-- TITLE -->
      <div
        class="d-flex justify-center align-center pb-2"
        :style="{borderBottom: `1px solid ${primaryColor}`}"
      >
        <!-- ICON -->
        <Icon
          :data="icons[data.data.navbar[0]['class']]"
          width="24"
          height="24"
          class="primary--text"
        />
        <!-- TEXT -->
        <div class="span title--text font-weight-bold ml-2">
          {{ data.data.navbar[0]['class'].toUpperCase() }}
        </div>
      </div>
    </div>

    <!-- TAB SLIDE -->
    <Swiper
      ref="gameTypeSlide"
      :options="swiperOption"
      class="mySwiper card1"
    >
      <SwiperSlide
        v-for="(vendorType, index) in data.data.navbar[0].items"
        :key="vendorType._id"
        class="py-2 d-flex justify-center subtitle-2 cursor-pointer"
        :class="activeVendorTypeIndex === index ? 'title--text' : 'textfield--text' "
        :style="activeVendorTypeIndex === index ? activeTabStyle : ''"
      >
        <div
          class="w-100 text-center"
          @click="setVendorActiveIndex(index)"
        >
          {{ vendorType.title }}
        </div>
      </SwiperSlide>
    </Swiper>

    <!-- VENDOR -->
    <Swiper
      ref="vendorSlide"
      @slideChange="slideVendorType"
    >
      <SwiperSlide
        v-for="vendorType in data.data.navbar[0].items"
        :key="vendorType._id"
        class="textfield--text subtitle-2"
      >
        <v-row
          class="ma-0 px-3 py-2"
        >
          <v-col
            v-for="hotGame in vendorType.hot_games"
            :key="hotGame._id"
            cols="6"
            class="px-0 pb-1 cursor-pointer"
            @click="launchGame(hotGame.game_code)"
          >
            <div class="px-1">
              <v-img
                class="rounded"
                :src="baseImgUrl + hotGame.game.image"
              />
              <div class="px-2 pt-1 title--text caption">
                {{ hotGame.localized_name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import card from '../../../../assets/icons/block/game/type1/card.svg'
import lottery from '../../../../assets/icons/block/game/type1/lottery.svg'
import live from '../../../../assets/icons/block/game/type1/live.svg'
import fish from '../../../../assets/icons/block/game/type1/fish.svg'
import slot from '../../../../assets/icons/block/game/type1/slot.svg'

import { mapGetters, mapState, mapActions } from 'vuex'
import { resStatus } from '../../../../utils/resUtils'

export default {

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    icons: {
      card,
      lottery,
      live,
      fish,
      slot,
    },

    swiperOption: {
      slidesPerView: 4.1,
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },

    vendorSwiperOption: {
      slidesPerView: 1,
      spaceBetween: 10,
    },

    activeVendorTypeIndex: 0,
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
    }),

    ...mapGetters(['theme', 'baseImgUrl']),

    /**
     * 取得 theme PRIMARY 色
     * @date 2021-12-13
     * @returns {string} primary 色碼
     */
    primaryColor() {
      return this.$vuetify.theme.themes[this.theme].primary
    },

    /**
     * 目前 active tab 的 style
     * @date 2021-12-13
     * @returns {object} active style
     */
    activeTabStyle() {
      return { borderBottom: `2px solid ${this.primaryColor}` }
    },
  },

  methods: {
    ...mapActions([
      'show_alert',
      'get_game_url',
      'set_progress_status',
    ]),

    /**
     * 滑動切換tab
     * @date 2021-12-13
     */
    slideVendorType() {
      this.activeVendorTypeIndex = this.$refs.vendorSlide.$swiper.activeIndex
      this.$refs.gameTypeSlide.$swiper.slideTo(this.$refs.vendorSlide.$swiper.activeIndex, 500)
    },

    /**
     * 點擊切換 vendor 區塊遊戲
     * @date 2021-12-13
     * @param {number} index 索引 (active 索引)
     */
    setVendorActiveIndex(index) {
      this.activeVendorTypeIndex = index
      this.$refs.vendorSlide.$swiper.slideTo(index, 500)
    },

    /**
     * 請先登入
     * @date 2021-12-10
     */
    noLogin() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('flashMessage.loginFirst'),
      })
    },

    /**
     * 取得遊戲連結 (成功)
     * @date 2021-12-09
     * @param {string} gameUrl
     */
    getGameUrlSuccess(gameUrl, progressFun = this.set_progress_status) {
      progressFun(false)
      window.open(gameUrl, '_blank')
    },

    /**
     * 取得遊戲連結 (失敗)
     * @date 2021-12-09
     */
    getGameUrlFail(e, progressFun = this.set_progress_status) {
      progressFun(false)
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 開啟遊戲
     * @date 2021-12-13
     * @param {string} gameCode='' 遊戲代碼
     * @param {function} launchGamefun=this.get_game_url 取得遊戲連結
     * @param {function} progressFun=this.set_progress_status 顯示進度條
     */
    async launchGame(gameCode = '', launchGamefun = this.get_game_url, progressFun = this.set_progress_status) {
      if (!this.isLogin) return this.noLogin()
      progressFun(true)
      const gameUrl = await launchGamefun({ gamecode: gameCode })
      resStatus(gameUrl, this.getGameUrlSuccess, this.getGameUrlFail)
    },
  },
}
</script>

<style lang="scss" scoped></style>
